import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthenticationService} from '@pwc-ecobonus/security';
import {Observable, of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {RoutePath} from './enums/route-path.enum';

@Injectable({
  providedIn: 'root'
})
export class AcceptTermsGuard implements CanActivate, CanActivateChild {

  private subscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.canProceed(next);

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  canDeactivate() {
    this.subscription.unsubscribe();
    return true;
  }

  canProceed(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {

    if (!environment.configuration.showPrivacy) {
      return of(true);
    }

    return this.authenticationService.hasUserAcceptedTerms().pipe(
      map((res) => {
          if (res) {
            return true;
          }

          return this.router.createUrlTree([RoutePath.ACCEPT_PRIVACY_POLICY]);

        }
      )
    );
  }
}
